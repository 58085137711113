import { Component, Inject } from "@angular/core";
import { LoginService } from 'src/app/modules/login/service/login-provider';
import { Login } from 'src/app/modules/login/models/login';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: "app-solicitar-autenticacao",
  templateUrl: "./solicitar-autenticacao.component.html",
  styleUrls: ["./solicitar-autenticacao.component.css"]
})
export class SolicitarAutenticacaoComponent {

  senhaVisivel: boolean = false;

  loginModel: Login;
  login;
  senha;

  mensagemErroAutenticacao: string = null;
  carregandoLogin: boolean;

  constructor(
    private loginProvider: LoginService,
    public dialogRef: MatDialogRef<SolicitarAutenticacaoComponent>,
    public _snackBar: MatSnackBar,
    ) {
      this.login = JSON.parse(localStorage.getItem("usuarioData")).login;
    }

  ngOnInit(): void {
  }

  public autenticar() {
    if (
      this.login != null &&
      this.senha != null &&
      this.login !== '' &&
      this.senha !== ''
    ) {
      this.carregandoLogin = true;

      this.loginModel = new Login();
      this.loginModel.login = this.login;
      this.loginModel.senha = this.senha;
      this.loginModel.idSistema = 2;

      this.loginProvider.autenticarUsuario(this.loginModel).subscribe(
        res => {
          this.carregandoLogin = false;
          this.mensagemErroAutenticacao = null;
          this.dialogRef.close(true)
        },
        err => {
          this.carregandoLogin = false;
          this.mensagemErroAutenticacao = err.error.message;
            this._snackBar.open(err.error.message, '', {
              duration: 4000,
              verticalPosition: 'top',
              horizontalPosition: 'end',
              panelClass: ['erro']
            });
        }
      );
    }
  }
}
