import { Injectable } from '@angular/core';
import { Router, ChildActivationEnd, RouterEvent } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NomeTituloService {
  // emissor de eventos protegido para ser encapsulado
  protected pathSubject: BehaviorSubject<{ nomeSecao: string }>;

  // getter que é visto externamente como propriedade readonly
  public get path() {
    return this.pathSubject;
  }

  constructor(private router: Router) {
    // inicialização com estado inicial
    this.pathSubject = new BehaviorSubject({ nomeSecao: '' });

    // assinatura dos eventos do router com emissão de dados no __pathSubject
    this.router.events.subscribe((event: RouterEvent) => {
      if (event && event instanceof ChildActivationEnd) {
        const { nomeSecao } = event.snapshot.firstChild.data;
        this.pathSubject.next({ nomeSecao });
      }
    });
  }

}
