import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { SolicitarAutenticacaoComponent } from 'src/app/shared/components/solicitar-autenticacao/solicitar-autenticacao.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SolicitarAutenticacaoService {

  constructor(public dialog: MatDialog) { }
  // Essa função cria um observable que retorna 'true' quando o usuario está autenticado
  abrirDialogo(){
    // Abre o Dialog para solicitar autenticação
    const referenciaDiaologo = this.dialog.open(SolicitarAutenticacaoComponent)
    // Cria e retorna um observable para com o valor o .subscribe podendo ser True ou False 
    return new Observable(observavel=>{
      referenciaDiaologo.afterClosed().
      subscribe(value => {
        observavel.next(value)
        observavel.complete();
      });
    })
  }
}

/* Para usar esse serviço voce tem que: 
  - Importar o modulo no *.module.ts do componente que voce vai usar:
      import { SolicitarAutenticacaoModule } from 'src/app/shared/components/solicitar-autenticacao/solicitar-autenticacao.module';
  - No *.component.ts voce deve importar o serviço e contruir a classe:
      import { SolicitarAutenticacaoService } from 'src/app/shared/services/solicitar-autenticacao.service';

      constructor(public autenticacao: SolicitarAutenticacaoService) { }
*/